<template>
<div class="banner">
                <Particles
                id="tsparticles"
                :particlesInit="particlesInit"
                :particlesLoaded="particlesLoaded"
                :options="{
                    background: {
                        color: {
                            value: 'transparent'
                        }
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: false,
                                mode: 'push'
                            },
                            onHover: {
                                enable: false,
                                mode: 'repulse'
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: ['#3687c8', '#b51f83']                     
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1
                        },
                        collisions: {
                            enable: true
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outModes: {
                                default: 'bounce'
                            },
                            random: false,
                            speed: 0.3,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1200
                            },
                            value: 80
                        },
                        opacity: {
                            value: 0.5
                        },
                        shape: {
                            type: 'circle'
                        },
                        size: {
                            value: 2,
                            random: true,
                            animation: {
                                enable: true,
                                speed: 10,
                                minimumValue: 0.1,
                                sync: false
                            },
                            move: {
                                enable: true,
                            },
                        },
                        animation: {
                            enable: true,
                            speed: 20,
                            minimumValue: 0.1,
                            sync: false
                        },
                    },
                    detectRetina: true
                }"
        />
        <div class="box-container title-container">
            <gradient-bar :reverse="true" height="250" ></gradient-bar>
            <h1>Metaverse</h1>            
            <img src="./../assets/banner-metaverse.png" alt="banner metaverse" class="banner-image" :style="{ right : imgBannerPos}" />           
        </div>
    </div>     

    <!-- section about -->
    <section class="about" id="def">
        <div class="box-container">
            <h2>Qu’est ce que le Metaverse ?</h2>
            <p>
                Après le changement de nom de <strong>Facebook en Meta</strong>, le mot <strong>« Metaverse »</strong> a suscité un nouvel engouement, mais personne ne sait vraiment ce qu’il signifie. Il s'agit pourtant d'un mot qui existe déjà depuis longtemps.<br/>
                Tout le monde y va de sa théorie au sujet du <strong>Metaverse</strong> mais beaucoup s'accordent sur le fait qu'il s'agit de <strong>l'Internet de demain</strong> !
            </p>
            <p><strong>Le Metaverse (ou Métavers en Français) est un réseau extrêmement évolutif de mondes virtuels interconnectés, axé sur l’interaction en temps réel, où les gens peuvent travailler, interagir socialement, effectuer des transactions, jouer et même créer.</strong></p>
            <p>Il utilise parfois des technologies avancées (AR, VR, capteurs haptiques, etc.) pour immerger encore plus l’utilisateur dans le monde virtuel. L’utilisateur peut alors interagir en direct avec un monde qui est toujours là et auquel il peut accéder quand il le souhaite.</p>
            <div class="row">
                <div class="col-50 first-col">
                    <p>De nombreux partisans pensent que la version futuriste parfaite du «Metaverse» consisterait en une plateforme unique où vous auriez votre personnalité, votre avatar, votre identité et des services de plateforme connectés, sous laquelle de nombreux mondes seraient créés et auxquels vous pourriez accéder.</p>
                    <p>Comme un monde avec de nombreux sous-mondes que vous pouvez rejoindre, quitter ou même créer. Les facteurs importants restent la définition de l’identité numérique, la propriété numérique, les monnaies numériques et la transférabilité universelle des actifs numériques. Tous ces éléments permettent une économie pleinement fonctionnelle dans le Metaverse.</p>
                </div>
                <div class="col-50">
                    <img src="./../assets/metaverse-globe.png" alt="globe" class="metaverse-globe" />
                </div>
            </div>
            <p><strong>Ainsi, chez Born2Mint, nous sommes persuadés que le Metaverse pourrait remplacer, dans un futur plus ou moins proche, certaines manières de faire du tourisme par exemple ou encore la participation à un concert ou la découverte d’expositions d’art, mais surtout la manière dont les gens apprennent, étudient, interagissent et même rencontrent des amis.</strong></p>
        </div>
    </section>

    <!-- section follow -->
    <section class="follow" id="follow">
        <div class="border-box-gradient box-container">
            <h2>Un accompagnement complet<br /> dans cette bascule vers de<br />nouveaux mondes ?</h2>
            <p>De plus en plus de Grandes Marques se positionnent dans le Metaverse. Elles y achètent des terrains virtuels dans des mondes digitaux tels que The Sandbox ou encore Decentraland et réfléchissent à proposer de nouvelles manières de créer du lien avec leurs clients et de nouvelles solutions à leur apporter.</p>
            <p>Nous accompagnons les marques et les sociétés dans leur projet Metaverse en construisant avec elles la meilleure stratégie à adopter pour qu'elles puissent atteindre leurs objectifs :</p>
            <ul>
                <li>Implantation de l'entreprise dans le Metaverse</li>
                <li>Organisation d'événement Metaverse</li>
                <li>Opération de lancement</li>
                <li>Transition vers le Web 3.0</li>
            </ul>
            
            <!-- Icons -->
            <div class="icons-container">
                <div class="icon-item" v-for="(item, idx) in icons" :key="idx">
                    <img :src="item.icon" alt="icon" />
                    <p v-html="item.text"></p>
                </div>
            </div>

            <!-- Logos -->
            <div class="box-border">
                <div class="box-border-inner">
                    <p>Liste non exhaustive des plateformes Metaverse que nous surveillons tout particulièrement</p>
                    <div class="logos-container">
                        <div class="col-50">
                            <img src="./../assets/Logo-TheSandbox.png" alt="logo the sandbox" />
                        </div>
                        <div class="col-50">
                            <img src="./../assets/Logo_Cryptoverse.png" alt="logo cryptoverse" />
                        </div>
                    </div>
                    <div class="logos-container reverse">
                        <div class="col-50">
                            <img src="./../assets/logo-decentraland.png" alt="logo decentraland" />
                        </div>
                        <div class="col-50">
                            <img src="./../assets/Logo_Bloktopia.png" alt="logo bloktopia" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- btn contact -->
            <div class="box-contact">
                <router-link :to="{name: 'Contact'}" class="btn">contactez-nous !</router-link>
            </div>
        </div>

    </section>  

</template>

<script>
import GradientBar from "./../components/GradientBar.vue";

export default {
    name : "Metaverse",
    components : {
        GradientBar
    },
    metaInfo: {
        title: 'Votre Guide-Expert dans le Metaverse afin de vous aider à basculer dans ces univers Web 3.0 nouvelle génération',
        meta: [
            { name: 'description', content: 'Born2Mint vous accompagne de A à Z dans votre projet Metaverse : The Sandbox, Decentraland, Bloktopia, Cryptoverse, etc' },
        ]
    },
    data() {
        return {
            icons : [
                {
                    icon : require("@/assets/icons-meta/meta-icon-1.png"),
                    text : "conseils &<br/> stratégie"
                },
                      {
                    icon : require("@/assets/icons-meta/meta-icon-2.png"),
                    text : "Sélection du<br /> Metaverse"
                },
                {
                    icon : require("@/assets/icons-meta/meta-icon-3.png"),
                    text : "Gestion immobilière de<br /> terrains virtuels"
                },
                {
                    icon : require("@/assets/icons-meta/meta-icon-4.png"),
                    text : "Modélisation 3D<br /> d'objets/d'assets"
                },
                {
                    icon : require("@/assets/icons-meta/meta-icon-5.png"),
                    text : "Modélisation 3D<br /> d'univers virtuels"
                },
                {
                    icon : require("@/assets/icons-meta/meta-icon-6.png"),
                    text : "Développement &<br /> Programmation"
                }
            ],
            imgBannerPos : "10%"
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
         handleScroll() {
             this.getPosition();
        },
        getPosition() {
            let pos = 10 - (window.pageYOffset / 70);
            this.imgBannerPos =  pos + "%";
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left:-50px;
}

h1 {
  color:#fff;
  font-size:60px;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
  margin:0;
  letter-spacing: 2px;
}

.banner-image {
    position:absolute;
    bottom:0;
    transition:0.5s;
    display: none;
}


/* Section About */
.about {
    padding:80px 0;
    background:$secondGradient;
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    position: relative;
    z-index: 1;
}

.about .row {
    align-items: center;
}

.about .col-50 {
    width:50%;
    margin:0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height:450px;
}

.about .first-col p {
    margin-right:10%;
}

.metaverse-globe {
    max-height:400px;
    position:relative;
    z-index:1;
    max-width: 90%;
    animation: bounce 4s infinite;
}


/* section follow */

section.follow {
    padding:80px 0 20px 0;
}

.border-box-gradient {
    padding:80px 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius:10px;
    background:#000;
    width:1320px;
    max-width: calc(90% - 80px);
    margin:40px auto;
}

.border-box-gradient:before {
    content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
   margin: -1px;
   border-radius: inherit;
   background: $mainGradient;
}

section.follow p{
    max-width: 80%;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    margin:20px 0;
    color:#fff;
    font-weight:300;
    font-size:20px;
}

.icons-container {
    display:flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin:80px auto;
}

.icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:29%;
    margin:40px 2%;
}

.icon-item img {
    height:60px;
    margin-bottom:20px;
}

.icon-item p {
    margin:0;
    font-size:20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color:#fff;
    line-height: 1.5em;
}

.box-border {
    margin:20px;
    position: relative;  
    border-radius:10px;
    background: $mainGradient;
    z-index:1;
}

.box-border-inner {
    padding:40px;
    background:#000;
    margin:1px;
    border-radius:10px;
      display: flex;
    flex-direction: column;
    align-items: center;
}

.box-border p {
    font-size:20px;
    text-transform: uppercase;
    color:#fff;
    text-align: center;
}

.logos-container {
    display: flex;
    align-items: center;
    width:100%;
    margin:20px 0;
}

.logos-container img {
    max-width: 60%;
}

.logos-container .col-50 {
    display: flex;
    justify-content: center;
}


.box-contact {
    display: flex;
    justify-content: center;
    margin-top:40px;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes bounce {
    0%  {
        margin-top:-20px;
    }

    50% {
        margin-top: 20px;
    }

    100% {
        margin-top: -20px;
    }
}

@media screen and (max-width:768px) { 
    h1 {
        font-size:40px;
    }

    .title-container {
        margin: 0 auto;
    } 

    .about .col-50 {
        width:100%;
    }

    .about .col-50.first-col {
        height:auto;
    }

    .about .first-col p {
        margin-right: 0;
    }

    .border-box-gradient {
        padding: 40px 10px;
        max-width: calc(96% - 20px);
    }

    section.follow p {
        max-width: 90%;
    }

    section.follow  ul {
        margin-left:30px;
    }

    .icons-container {
        margin:20px auto;
    }

    .icon-item {
        width:90%;
        margin:20px auto;
    }

    .box-border-inner {
        padding:20px;
    }

    .logos-container {
        flex-direction: column;
        margin:0;
    }

    .logos-container img {
        margin:20px auto;
    }

}


</style>